"use client";
import { useEffect, useRef } from "react";
import styles from "@/app/styles/Discover/Discover.module.scss";

export default function DiscoverSectionNews({
	data,
	activeIndex,
	setActiveIndex,
}: any) {
	const containerRef = useRef<HTMLDivElement>(null);
	const containerSRef = useRef<HTMLDivElement>(null);
	const changeActiveIndex = (newIndex: number) => {
		setActiveIndex(newIndex);
	};
	useEffect(() => {
		containerRef?.current?.scrollTo(
			0,
			75 * activeIndex - containerRef?.current?.clientHeight / 2
		);
	}, [activeIndex]);

	return data !== null ? (
		<div className={styles.discover__news} ref={containerRef}>
			{data?.map((discoverItem: any, index: number) => {
				const isActive: boolean = activeIndex === index;
				return (
					<div
						className={`${styles.discover__news__wrapper} ${
							isActive ? styles.discover__news__wrapper__active : ""
						}`}
						key={`WrapperNews-${index}`}
						onClick={() => changeActiveIndex(index)}
						ref={containerSRef}
					>
						<div className={styles.discover__news__wrapper__title}>
							<div className={styles.discover__news__wrapper__title__clamp}>
								<h2 className={styles.discover__news__wrapper__title__text}>
									{discoverItem?.principal_content?.title}
								</h2>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	) : (
		<div>
			<div />
		</div>
	);
}
