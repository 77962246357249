"use client";

import { useState } from "react";
import styles from "@/app/styles/Blocks/BloqueHeroSpecial.module.scss";
import { Thumbnail } from "@/app/types/Thumbnail.type";
import ClientImage from "@/app/components/atoms/ClientImage/ClientImage";
import Icon from "@/app/components/atoms/Icon/Icon";
import PlayerVodV2 from "../../molecules/PlayerVodV2/PlayerVodV2";

type HeroSpecialProps = {
    bullet: string;
    duration: string;
    formatDate: string;
    isLiveBlog?: boolean;
    isVideo?: boolean;
    name: string;
    publish: string;
    thumbnail: Thumbnail;
    title: string;
    isTapToPlay?: boolean;
    isFirstFold?: boolean;
    videoId: string;
};

const HeroSpecial = ({
    bullet,
    duration,
    formatDate,
    isLiveBlog = false,
    isVideo = false,
    name,
    publish,
    thumbnail,
    title,
    isTapToPlay = false,
    isFirstFold = false,
    videoId,
}: HeroSpecialProps) => {
    const [showPlayer, setShowPlayer] = useState<boolean>(false);
    const [videoStyle, setVideoStyle] = useState<string>("");

    const activePlayer = () => {
        setShowPlayer(true);
        setVideoStyle(styles["hero--videoLayout"]);
    };

    const clickVideo = isVideo && isTapToPlay ? () => activePlayer() : () => {};

    return (
        <div className={`${styles.hero__special} ${styles["hero--layout"]} ${videoStyle}`}>
            {!showPlayer ? (
                <>
                    <figure className={`${styles.hero__figure} ${styles["hero__figure--layout"]}`} onClick={() => clickVideo()}>
                        <ClientImage
                            priority={isFirstFold}
                            thumbnail={thumbnail}
														breakpoints={true}
                            customsizes="(min-width: 420px) 100vw, (min-width: 768px) 100vw, (min-width:1024px) 100vw, (min-width: 1200px) 100vw"
                        />

                        <figcaption className={`${styles.hero__infoCard}`}>
                            <div className={`${styles.hero__main}`}>
                                {isLiveBlog && (
                                    <div className={`${styles.hero__main_liveblog}`}>
                                        <span>LIVEBLOG</span>
                                    </div>
                                )}
                                <span>{name}</span>
                            </div>

                            <div className={`${styles.hero__body}`}>
                                <h2>{title}</h2>
                                {bullet && <p>{bullet}</p>}
                            </div>

                            <div className={`${styles.hero__end}`}>
                                <time dateTime={publish}>{formatDate}</time>
                                {isVideo && (
                                    <div>
                                        <strong>Duración</strong>
                                        <time>{duration}</time>
                                    </div>
                                )}
                            </div>
                        </figcaption>
                    </figure>

                    {isVideo && isTapToPlay && (
                        <div className={`${styles.hero__iconPlay}`}>
                            <Icon name="newPlayer" onClick={() => activePlayer()} />
                        </div>
                    )}
                    {isVideo && !isTapToPlay && (
                        <div className={`${styles.hero__iconPlay}`}>
                            <Icon name="newPlayerLink"/>
                        </div>
                    )}
                </>
            ) : (
                <div className={`${styles.hero__player}`}>
                    <PlayerVodV2
                        idVideo={videoId}
                        type="clips"
                        cmsid={true}
                        mute={true}
                        autoplay={true}
                        currentPlaceholder={thumbnail?.imageUrl?.webp}
                    />
                </div>
            )}
        </div>
    );
};

export default HeroSpecial;
