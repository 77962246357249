"use client";
import { useState, useRef } from "react";
import { usePathname } from "next/navigation";
import dynamic from "next/dynamic";
import Script from "next/script";
import ReactDOM from "react-dom";
import { v4 as uuid } from "uuid";
import { useResponsive } from "@/app/hooks/useResponsive";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import style from "@/app/styles/Blocks/BloqueVideoVertical.module.scss";
import Slider from "@/app/components/molecules/Slider/Slider";
import Carousel from "@/app/components/molecules/Carousel/Carousel";
const LightBox = dynamic(
	() => import("@/app/components/molecules/LightBox/LightBox")
);
const CardSingleVideo = dynamic(
	() =>
		import("@/app/components/molecules/cards/CardSingleVideo/CardSingleVideo")
);

export default function BloqueTalentsTabs({
	linkMoreInfo,
	videos,
	...rest
}: any) {
	ReactDOM.preconnect("https://player-assets.nmas.com.mx/", {
		crossOrigin: "anonymous",
	});
	const pathName = usePathname();
	const [isligthBoxOpen, setIsLigthBoxOpen] = useState(false);
	const [modalId, setModalId] = useState("");
	const [slides, setSlides] = useState([]);
	const columnProgramsRef = useRef<HTMLDivElement>(null);
	const { isDesktop, isTablet, isMobile } = useResponsive();
	const device = isDesktop ? "desktop" : isTablet ? "tablet" : "mobile";

	const handleCloseModal = () => {
		setIsLigthBoxOpen(false);
	};

	const getLowerElements = (videos: any, index: any) => {
		const length = videos?.length;

		if (index === 0) {
			return [videos[length - 1], videos[length - 2]];
		} else if (index === 1) {
			return [videos[0], videos[length - 1]];
		} else {
			return videos?.slice(index - 2, index);
		}
	};

	const getGreaterElements = (videos: string[], index: number) => {
		const length = videos?.length;
		const result: string[] = [];

		for (let i = 1; i < length - 2; i++) {
			const videoIndex = (index + i) % length;
			result?.push(videos[videoIndex]);
		}

		return result;
	};

	const showLightBox = (index: any) => {
		const clickedElement = videos[index];
		const lowerElements = getLowerElements(videos, index);
		const greaterElements = getGreaterElements(videos, index);
		const results: any = [];
		const modalID = uuid();

		if (device === "desktop" || device === "tablet") {
			results?.push(...lowerElements);
			results?.push(clickedElement);
			results?.push(...greaterElements);
		}

		if (device === "mobile") {
			results?.push(clickedElement);
			results?.push(...greaterElements);
			results?.push(...lowerElements);
		}

		setSlides(results);
		setIsLigthBoxOpen(true);
		setModalId(modalID);
		rest.blockData.content_name = `VOD>`;
		rest.blockData.contentTitle = videos[index]?.title;
		handleDataLayerOnClick(rest?.blockData, index + 1, pathName);
	};

	const initSDK = () => {
		if (typeof globalThis.window !== "undefined") {
			window.VideoSDK.AMP.init({
				vendor: "jw",
				loglevel: "debug",
				connection: {
					env: "prod",
					profileId: "c09ce787-4010-4bdf-96ad-fe785b6eca50",
				},
			});
		}
	};

	return (
		<>
			<Script src={process.env.NEXT_PUBLIC_SCRIPT_PLAYER} onLoad={initSDK} />
			<div className={`${style["videoVertical__wrapperSlider"]}`}>
				<div
					className={`${style["videoVertical__wrapperCard"]}`}
					ref={columnProgramsRef}
				>
					<Slider
						breakpoints={{
							320: { slidesPerView: 2.2, spaceBetween: 8 },
							550: { slidesPerView: 5.2, spaceBetween: 8 },
							834: { slidesPerView: 6.5, spaceBetween: 8 },
							1440: { slidesPerView: 6.5, spaceBetween: 8 },
						}}
						// slidesPerView={6.5}
					>
						{videos?.map((item: any, index: any) => (
							<CardSingleVideo
								onClick={() => showLightBox(index)}
								key={index}
								image={item.image}
								position={rest?.position}
							/>
						))}
					</Slider>
				</div>
			</div>
			{isligthBoxOpen && (
				<LightBox onClose={handleCloseModal}>
					<Carousel
						modalID={modalId}
						slides={slides}
						ctaCard={linkMoreInfo}
						deviceSize={device}
					/>
				</LightBox>
			)}
		</>
	);
}
