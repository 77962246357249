"use client";
import { useState } from "react";
import styles from "@/app/styles/Discover/Discover.module.scss";
import DiscoverSectionNews from "@/app/components/molecules/DiscoverMolecules/DiscoverSectionNews";
import MainDiscover from "@/app/components/molecules/DiscoverMolecules/MainDiscover";
import { ToggleProvider } from "@/app/context/ToggleContext";

export default function Discover({ data }: any) {
	const [activeIndex, setActiveIndex] = useState(0);
	return (
		<>
			<div className={styles.discover__container__news}>
				<h1 className={styles.discover__container__news__title}>
					Descubre hoy:
				</h1>
				<DiscoverSectionNews
					data={data}
					activeIndex={activeIndex}
					setActiveIndex={setActiveIndex}
				/>
			</div>
			<ToggleProvider >
				<MainDiscover
					data={data}
					activeIndex={activeIndex}
					setActiveIndex={setActiveIndex}
				/>
			</ToggleProvider>
		</>
	);
}
