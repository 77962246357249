"use client";
import Script from "next/script";
import { useCallback, useEffect, useId, useState } from "react";
import foro_bg from "@/assets/live/screen-foro.jpg";
import nmas_bg from "@/assets/live/screen-nmas.jpg";
import nmedia_bg from "@/assets/live/screen-n-media.jpg";
import ReactDOM from "react-dom";

type Props = {
	ui: string;
	uid: string;
	path: string;
	isLiveBlog?: boolean;
	isFloating?: boolean;
};

declare global {
	interface Window {
		VideoSDK?: any;
	}
}

export default function PlayerStreaming({
	ui,
	path,
	uid,
	isLiveBlog,
	isFloating,
}: Props) {
	ReactDOM.preconnect("https://player-assets.nmas.com.mx/", {
		crossOrigin: "anonymous",
	});
	const nodeID = useId(); // `player_streaming-${path}`;
	const [sdkLoaded, setSDKLoaded] = useState(false);
	function initSDK() {
		if (typeof globalThis.window !== "undefined") {
			window.VideoSDK.AMP.init({
				vendor: "jw",
				loglevel: "debug",
				connection: {
					env: "prod",
				},
			});
			setSDKLoaded(() => {
				return window.VideoSDK.AMP.ready;
			});
		}
	}
	let currentPlaceholder = nmedia_bg?.src;
	switch (path) {
		case "noticieros-en-vivo":
			currentPlaceholder = nmas_bg?.src;
			break;
		case "forotv-en-vivo":
			currentPlaceholder = foro_bg?.src;
			break;
		default:
			currentPlaceholder = nmedia_bg?.src;
			break;
	}

	const startStreaming = useCallback(
		(ui: string, path: string) => {
			window.VideoSDK.AMP.playLivestream({
				nodeId: nodeID,
				uid,
				ui,
				path,
				autoplay: true,
				autopause: {
					unique: true,
				},
				parent: {
					site: "nmas",
				},
			});
		},
		[nodeID, uid]
	);

	useEffect(() => {
		if (sdkLoaded) {
			startStreaming(ui, path);
		} // END IF

		// VERIFICA QUE EL SCRIPT YA ESTÉ CARGADO Y EL SDK INICIALIZADO
		// FUNCIONA PARA LAS NAVEGACIONES SUBSECUENTES
		if (typeof globalThis.window.VideoSDK !== "undefined") {
			startStreaming(ui, path);
		}
		return () => globalThis?.window?.VideoSDK?.AMP?.destroy(nodeID);
	}, [nodeID, path, sdkLoaded, startStreaming, ui]); // END EFFECT

	useEffect(() => {
		const playerInstance = window?.VideoSDK?.AMP?.getAMPlayerInstance(nodeID);

		if (playerInstance && typeof playerInstance.setFloating === "function") {
			playerInstance.setFloating(!isFloating);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFloating]);
	return (
		<div className={isLiveBlog ? "live_liveBlog liveText" : "liveText"}>
			<Script src={process.env.NEXT_PUBLIC_SCRIPT_PLAYER} onLoad={initSDK} />
			<div
				id={nodeID}
				style={{
					position: "relative",
					aspectRatio: "16 / 9",
					width: "100%",
					minWidth: "100%",
					height: isLiveBlog ? "auto" : "calc(100% * 0.5625)",
				}}
			>
				<img
					src={currentPlaceholder}
					alt="Imagen default canal"
					width="328"
					height="184.5"
					style={{ width: "100%", height: "auto", aspectRatio: "16 / 9" }}
				/>
			</div>
		</div>
	);
}
