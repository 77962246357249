import { useContext, useEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { SwiperSlide, Swiper } from "swiper/react";
import { ToggleContext } from "@/app/context/ToggleContext";
import { FreeMode, Scrollbar, Mousewheel, Navigation } from "swiper/modules";
import { useResponsive } from "@/app/hooks/useResponsive";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import styles from "@/app/styles/Discover/Discover.module.scss";
const PlayerVodV2 = dynamic(
	() => import("@/app/components/molecules/PlayerVodV2/PlayerVodV2")
);
const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));
const RelatedContentDiscover = dynamic(
	() => import("./RelatedContentDiscover")
);
const TooltipIcon = dynamic(
	() => import("@/app/components/molecules/tooltipedIcon/tooltipIcon")
);

declare const window: WindowWithDataLayer;

export default function MainDiscover({
	data = [],
	activeIndex = 0,
	setActiveIndex,
}: any) {
	const { isDesktop } = useResponsive();
	const { setToggle: setIsOpenShareDialog } = useContext(ToggleContext);
	const [seeRelated, setSeeRelated] = useState(false);
	const [heightDocument, setHeightDocument] = useState<number | null>(800);
	const [isPlaying, setIsPlaying] = useState<boolean>(true);
	const swiperRef = useRef(null);

	const [isMuted, setIsMuted] = useState<boolean>(true);

	const minSwipeDistance = 50;
	let touchStart: any = null;
	let touchEnd: any = null;

	const onTouchStart = (e: any) => {
		touchEnd = null;
		touchStart = e?.targetTouches[0]?.clientX;
	};
	const onTouchMove = (e: any) => {
		touchEnd = e?.targetTouches[0]?.clientX;
	};

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		// if (!seeRelated && !isLeftSwipe && distance < minSwipeDistance * -1) {
		// 	router.push("/");
		// 	return;
		// }
		if (isLeftSwipe || isRightSwipe) {
			setSeeRelated(isLeftSwipe ? true : false);
			isLeftSwipe
				? // @ts-ignore
				  swiperRef?.current?.swiper?.disable()
				: // @ts-ignore
				  swiperRef?.current?.swiper?.enable();
		}
	};

	// const onVideoEnded = () => {
	// 	if (window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${activeIndex}`)) {
	// 		if (isPlaying) {
	// 			if (
	// 				!window?.VideoSDK.AMP.getAMPlayerInstance(
	// 					`player-${activeIndex}`
	// 				).getMute()
	// 			) {
	// 				setIsMuted(false);
	// 			}
	// 		}
	// 	} else {
	// 		setIsMuted(true);
	// 	}
	// };

	const audioVideo = (activeIndex: number, muted: boolean) => {
		setIsMuted(muted);
		if (muted === false) {
			window?.VideoSDK?.AMP?.unmute(`player-${activeIndex}`);
		} else {
			window?.VideoSDK?.AMP?.mute(`player-${activeIndex}`);
		}

		const dlData = {
			event: "select_content",
			clickBloque: undefined,
			colorBloque: "dark",
			content_name: isMuted ? "unmute" : "mute",
			content_type: "button",
			contenidosBloque: undefined,
			countWidget: undefined,
			descripcionBloque: undefined,
			estiloBloque: undefined,
			eventAction: "click",
			posicionBloque: undefined,
			subBloque: undefined,
			tamanoBloque: undefined,
			tipoBloque: undefined,
			tipoElemento: "button",
			tituloBloque: undefined,
			contentTitle: isMuted ? "mute" : "unmute",
		};
		handleDataLayerOnClick(
			dlData,
			0,
			data?.[activeIndex]?.principal_content?.url,
			true
		);
	};

	useEffect(() => {
		// @ts-ignore
		swiperRef?.current?.swiper?.slideTo(activeIndex);
		document.title =
			data?.[activeIndex]?.principal_content?.title || "Descubre N+ | N+";
		return () => {
			if (window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${activeIndex}`)) {
				window?.VideoSDK?.AMP?.destroy(`player-${activeIndex}`);
			}
		};
	}, [activeIndex, data]);

	const changeSlide = (activeIndex: number, previousIndex: number) => {
		window?.history?.pushState(
			{
				...window?.history?.state,
				path: data?.[activeIndex]?.principal_content?.url,
			},
			"",
			data?.[activeIndex]?.principal_content?.url
		);
		document
			.querySelector("[rel=canonical]")
			?.setAttribute(
				"href",
				`${process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${
					data?.[activeIndex]?.principal_content?.url
				}`
			);
		document
			.querySelector("[property='og:image']")
			?.setAttribute(
				"content",
				`${process.env.NEXT_PUBLIC_ASSETS}/${data?.[activeIndex]?.principal_content?.id_video}/snapshot.jpg`
			);
		document
			.querySelector("[property='twitter:image']")
			?.setAttribute(
				"content",
				`${process.env.NEXT_PUBLIC_ASSETS}/${data?.[activeIndex]?.principal_content?.id_video}/snapshot.jpg`
			);
		document.title =
			data?.[activeIndex]?.principal_content?.title || "Descubre N+ | N+";
		// @ts-ignore
		swiperRef?.current?.swiper?.slideTo(activeIndex);

		if (data?.length !== previousIndex) {
			if (
				window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${previousIndex}`)
			) {
				window?.VideoSDK?.AMP?.getAMPlayerInstance(
					`player-${previousIndex}`
				)?.pause();
			}
		}

		try {
			if (
				window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${activeIndex - 2}`)
			) {
				window?.VideoSDK?.AMP?.destroy(`player-${activeIndex - 2}`);
			}
			if (
				window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${activeIndex + 2}`)
			) {
				window?.VideoSDK?.AMP?.destroy(`player-${activeIndex + 2}`);
			}
		} catch (e) {}

		setActiveIndex(activeIndex);

		setTimeout(() => {
			if (window?.VideoSDK?.AMP?.getAMPlayerInstance(`player-${activeIndex}`)) {
				const current = data?.[activeIndex]?.principal_content;
				const sessionData = window.sessionStorage.getItem("sessionData");
				let prevPath;
				if (sessionData) {
					const sDataParsed = JSON.parse(sessionData);
					prevPath = sDataParsed.prevPath;
				}
				window.dataLayer.push({
					event: "pageView",
					author: "N+",
					etiquetas: [],
					page_location: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(
						/\/+$/,
						""
					)}${current?.url}`,
					page_path: `${current?.url}`,
					page_title: current?.title,
					page_previous: prevPath,
					secccion: "/descubre/",
					tipoContenido: `Discover ${current?.url}`,
				});
				window.sessionStorage.setItem(
					"sessionData",
					JSON.stringify({
						prevPath: current?.url,
						countWidget: undefined,
						prevBlock: undefined,
						prevElemntType: "Slide",
						blockPosition: undefined,
					})
				);

				window?.VideoSDK?.AMP?.getAMPlayerInstance(
					`player-${activeIndex}`
				)?.play();

				if (isMuted) {
					window?.VideoSDK?.AMP?.mute(`player-${activeIndex}`);
				} else {
					window?.VideoSDK?.AMP?.unmute(`player-${activeIndex}`);
				}
			}
		}, 1000);
	};

	useEffect(() => {
		window?.history?.pushState(
			{ ...window?.history?.state, path: data?.[0]?.principal_content?.url },
			"",
			data?.[0]?.principal_content?.url
		);
	}, [data]);

	return (
		<>
			<div
				className={styles.discover__main__container}
				onTouchMove={onTouchMove}
				onTouchStart={onTouchStart}
				onTouchEnd={onTouchEnd}
			>
				<Swiper
					// @ts-ignore
					ref={swiperRef}
					allowSlideNext={data?.length === activeIndex ? false : true}
					direction={"vertical"}
					scrollbar={true}
					navigation={true}
					mousewheel={{ thresholdTime: 1000 }}
					allowTouchMove={true}
					simulateTouch={true}
					modules={[FreeMode, Scrollbar, Mousewheel, Navigation]}
					preventInteractionOnTransition={true}
					className="mySwiper"
					longSwipesMs={1000}
					onTransitionEnd={({ activeIndex, previousIndex }) => {
						changeSlide(activeIndex, previousIndex);
					}}
					onTransitionStart={() => {
						setIsOpenShareDialog(false);
					}}
					style={{
						height: heightDocument
							? `calc(${heightDocument}px - 62px)`
							: `calc(100vh - 124px)`,
					}}
				>
					{data
						?.filter((item: any) => !item?.slug)
						?.map((discoverItem: any, index: number) => {
							return (
								<SwiperSlide key={`SwiperSlide-${index}`}>
									<div className={styles.discover__main__container__wrapper}>
										<div
											className={
												styles.discover__main__container__wrapper__player_wrapper
											}
										>
											<div
												className={
													styles.discover__main__container__wrapper__player_wrapper__wrapper
												}
											>
												<button
													onClick={() => audioVideo(index, !isMuted)}
													className={
														styles.discover__main__container__wrapper__player_wrapper__wrapper__button
													}
												>
													<Icon name={isMuted ? "VolumeOff" : "VolumeUp"} />
												</button>
												<div
													className={
														styles.discover__main__container__wrapper__player_wrapper__wrapper__wrapper_button
													}
												>
													<TooltipIcon
														variant="share"
														variantDiscover={true}
														isDarkMode={true}
														dataCardDiscover={discoverItem}
														isDiscover={true}
													/>
												</div>
											</div>
											<div
												className={
													styles.discover__main__container__wrapper__player_wrapper__wrapper__player
												}
											>
												{activeIndex > index - 1 && activeIndex < index + 1 ? (
													<PlayerVodV2
														idVideo={discoverItem?.principal_content?.id_video}
														autoplay={activeIndex === index}
														mute={isMuted}
														controls={false}
														loop={true}
														// onVideoEnded={() => {
														// 	onVideoEnded();
														// }}
														cmsid
														manualVodId={`${index}`}
														currentPlaceholder={discoverItem?.image?.imageUrl}
														isVerticalVideo={true}
													/>
												) : (
													<div
														id="player-wrapper"
														className="hmedia video-stream u-video"
														style={{
															width: "100%",
															height: "100%",
															position: "absolute",
															backgroundColor: "#00283B",
														}}
													>
														<div className="lds-dual-ring" />
														<Image
															src={
																discoverItem?.image?.imageUrl ??
																"/assets/placeholder_.svg"
															}
															alt="Video imagen default "
															width="384"
															height="216"
															loading="lazy"
															placeholder="blur"
															blurDataURL={`${
																process.env.NEXT_PUBLIC_BLURIMAGE ||
																"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
															}`}
															style={{
																position: "absolute",
																top: "0",
																left: "0",
																width: "100%",
																height: "100%",
																objectFit: "contain",
															}}
														/>
													</div>
												)}

												<span className="overlay" />
											</div>
										</div>
										{activeIndex === index || isDesktop ? (
											<RelatedContentDiscover
												data={discoverItem}
												seeRelated={seeRelated}
												heightDocument={heightDocument}
											/>
										) : (
											<div className={`${styles["discover__relatedVoid"]}`} />
										)}

										<div
											style={{
												height: "100%",
												width: "64px",
												padding: "16px",
											}}
										>
											<div
												style={{
													height: "100%",
													width: "100%",
													display: "flex",
													flexDirection: "column",
													justifyContent: "space-between",
												}}
											>
												{" "}
											</div>
										</div>
									</div>
								</SwiperSlide>
							);
						})}
				</Swiper>
			</div>
		</>
	);
}
